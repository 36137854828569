export default [
    {
      key: "lead_name",
      label: "Names",
      visible: true
    },
    {
      key: "status_lead",
      label: "Status",
      visible: true
    },
    {
      key: "credit_report",
      label: "CR",
      visible: true
    },
    {
      key: "mobile",
      label: "Mobile",
      visible: true
    },
    {
      key: "done",
      label: "Done",
      visible: true
    }
]