export default  [
    {
      options: [
        { value: 0, label: "All" },
        { value: 1, label: "Missing" },
        { value: 2, label: "Done" }
      ],
      model: "",
      primaryKey: "value",
      labelSelect: "label",
      cols: 12,
      md: 2,
      visible: true
    }
]